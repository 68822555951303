import styled from '@emotion/styled'
import { baseStyles } from './_config.js'

// Bootstrap Layout Components for Overwritting
import bContainer from 'react-bootstrap/Container'
import bRow from 'react-bootstrap/Row'
import bCol from 'react-bootstrap/Col'

// ------ Layout Styles

export const Container = styled(bContainer)`
  padding: ${baseStyles.basePadding}px 0px!important;
  @media(max-width: 992px) {
    padding: ${baseStyles.basePadding / 2}px 0px!important;
  }
`

export const Row = styled(bRow)`
  max-width: ${baseStyles.maxWidth}px!important;
  margin: 0 auto!important;
`

export const Col = styled(bCol)`

`
