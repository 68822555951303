import styled from '@emotion/styled'
import { Link as gLink } from 'gatsby';
import { linkStyles as config, baseStyles } from './_config'

export const A = styled.a`
  color: #FFFFFF;
  margin: 0 45px 0 0;
  font-size: ${config.linkFontSize}rem;
  font-family: ${config.linkFontFamily};
  font-weight: ${config.linkFontStyle};
  font-style: ${config.linkFontWeight};
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const Link = styled(gLink)`
  color: ${baseStyles.colorTertiary};
  margin: 0 45px 0 0;
  font-size: ${config.linkFontSize}rem;
  font-family: ${config.linkFontFamily};
  font-weight: ${config.linkFontStyle};
  font-style: ${config.linkFontWeight};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
  margin: 0;
  display: inline-block;
  position: relative;
  transition: 0.2s;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    height: 2px;
    background: ${baseStyles.colorSecondary};
    transition: 0.2s;
  }
  &:hover {
    text-decoration: none;
    color: ${baseStyles.colorSecondary};
    &:after {
      width: 100%;
    }
  }
`

export const FooterLink = styled(gLink)`
  color: #FFFFFF;
  margin: 0 45px 0 0;
  font-size: ${config.linkFontSize  + 0.5};
  font-family: ${config.linkFontFamily};
  font-weight: ${config.linkFontStyle};
  font-style: ${config.linkFontWeight};
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`