import React from 'react'
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'

// Components
import {Container, Row, Col} from '../html/Layout'
import { ULNH, LIH } from '../html/Fonts'
import { Link } from '../html/Links'
import { baseStyles } from '../html/_config'
import BlogNav from '../features/BlogNav';


export default class MainNav extends React.Component {
  constructor() {
    super()

    this.state = {
      visibleStoriesOfCare: false
    }

    this.showStoriesOfCare = this.showStoriesOfCare.bind(this)
  }

  showStoriesOfCare(e) {
    this.setState({visibleStoriesOfCare: true})
    const link = e.currentTarget.getBoundingClientRect()
    const storiesOfCare = document.querySelector('#blog-nav').getBoundingClientRect()

    const hideStoriesOfCare = (event) => {
      if(
        (event.clientX < link.left - 1 && event.clientY < storiesOfCare.top) ||
        (event.clientX > link.right && event.clientY < storiesOfCare.top) || 
        event.clientY < link.top || event.clientY > storiesOfCare.bottom
      ) {
        this.setState({visibleStoriesOfCare: false})
        window.removeEventListener('mousemove', hideStoriesOfCare)
      }
    }

    window.addEventListener('mousemove' , hideStoriesOfCare)
  }

  componentDidMount() {
    document.querySelector('#blog-nav').top = document.querySelector('#main-nav').clientHeight
  }

  render() {
    return(
      <Container id='main-nav' as='nav' fluid css={css`background: ${baseStyles.colorPrimary}; margin-bottom: 0; padding: 15px ${baseStyles.basePadding}px!important;`}>
        <Global
          styles={css`
            .active-link {
              color: ${baseStyles.colorSecondary}!important;
              &::after {
                width: 100%!important;
              }
            }
          `}
        />
        <BlogNav visible={this.state.visibleStoriesOfCare}/>
        <Row>
          <Col xs={3} as='section' css={css`display: flex; align-items: center;`}>
            <Link to='/' css={css`&::after{display:none;}`}>HonorThyParents.net</Link>
          </Col>
          <Col xs={9} as='section'>
            <ULNH css={css`justify-content: flex-end;`}>
              <LIH>
                <Link to='/book' activeClassName='active-link'>Book</Link>
              </LIH>
              <LIH>
                <Link 
                  to='/stories-of-care'
                  activeClassName='active-link' 
                  onMouseEnter={this.showStoriesOfCare}
                >
                  Stories of Care
                </Link>
              </LIH>
              <LIH css={css`margin-right: 0;`}>
                <Link to='/contact' css={css`margin-right: 0;`} activeClassName='active-link'>Contact</Link>
              </LIH>
            </ULNH>
          </Col>
        </Row>
      </Container>
    )
  }
}