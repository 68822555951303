/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import {Container, Row, Col} from '../html/Layout'
import { H2 } from '../html/Fonts'
import { baseStyles } from '../html/_config'


const MainFooter = () => (
  <Container as='nav' fluid css={css`background: ${baseStyles.colorPrimary}; margin-bottom: 0;`}>
    <Row>
      <Col xs={12} as='section' css={css`display: flex; align-items: center; justify-content: center;`}>
        <H2 css={css`
          position: relative;
          color: ${baseStyles.colorQuinary};
          font-size: ${baseStyles.baseFontSize * 2}rem;
          font-family: ${baseStyles.tertiaryFontFamily};
          margin: 0;
        `}>
          HonorThyParents.net
          <span css={css`
            position: absolute;
            font-size: ${baseStyles.baseFontSize * 0.5}rem;
            bottom: -0.25rem;
            right: -3rem;
          `}>
            Copyright © 2019
          </span>
        </H2>
      </Col>
    </Row>
  </Container>
)

export default MainFooter