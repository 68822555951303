import React from "react"
import { Global, css } from "@emotion/core"
import Helmet from "react-helmet"
import MainNav from "./page-elements/MainNav"
import MainFooter from "./page-elements/MainFooter"

const Layout = ({ children }) => (
  <>
    <Global
      styles={css`
        body {
          margin: 0;
        }
      `}
    />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
    </Helmet>
    <MainNav />
    {children}
    <MainFooter />
  </>
)

export default Layout
