import styled from '@emotion/styled'
import { baseStyles, fontStyles } from './_config'


// ------ Title Font Styles


export const H1 = styled.h1`
  color: ${baseStyles.colorPrimary};
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  font-size: ${baseStyles.baseFontSize * 1.875}rem;
  font-family: ${fontStyles.titleFontFamily};
  font-weight: ${fontStyles.titleFontWeight};
  font-style: ${fontStyles.titleFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H2 = styled.h2`
  color: ${baseStyles.colorPrimary};
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  font-size: ${baseStyles.baseFontSize * 1.625}rem;
  font-family: ${fontStyles.titleFontFamily};
  font-weight: ${fontStyles.titleFontWeight};
  font-style: ${fontStyles.titleFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H3 = styled.h3`
  color: ${baseStyles.colorPrimary};
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  font-size: ${baseStyles.baseFontSize * 0.75}rem;
  font-family: ${baseStyles.primaryFontFamily};
  font-weight: bold;
  font-style: ${fontStyles.titleFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H4 = styled.h4`
  color: ${baseStyles.colorQuaternary};
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  font-size: ${baseStyles.baseFontSize * 0.75}rem;
  font-family: ${baseStyles.secondaryFontFamily};
  font-weight: normal;
  font-style: ${fontStyles.subtitleFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H5 = styled.h5`
  color: ${fontStyles.subtitleColor};
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  font-size: ${baseStyles.baseFontSize * 0.625}rem;
  font-family: ${baseStyles.secondaryFontFamily};
  font-weight: regular;
  font-style: ${fontStyles.subtitleFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`


// ------ Paragraph Font Styles


export const P = styled.p`
  color: ${baseStyles.colorPrimary};
  margin-bottom: ${fontStyles.paragraphSpacing}px;
  font-size: ${baseStyles.baseFontSize }rem;
  font-family: ${fontStyles.paragraphFontFamily};
  font-weight: ${fontStyles.paragraphFontWeight};
  font-style: ${fontStyles.paragraphFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const Strong = styled.strong`
  font-weight: bold;
`


// ------ List Styles


export const UL = styled.ul`
  margin-bottom: ${baseStyles.baseMargin}px;
`
export const ULNH = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`

export const OL = styled.ol`
  margin-bottom: ${baseStyles.baseMargin}px;
`

export const LIV = styled.li`
  margin-bottom: ${baseStyles.baseMargin}px;
  font-size: ${baseStyles.baseFontSize}rem;
  font-family: ${fontStyles.paragraphFontFamily};
  font-weight: ${fontStyles.paragraphFontWeight};
  font-style: ${fontStyles.paragraphFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const LIH = styled.li`
  margin-right: ${baseStyles.baseMargin}px;
  font-size: ${baseStyles.baseFontSize}rem;
  font-family: ${fontStyles.paragraphFontFamily};
  font-weight: ${fontStyles.paragraphFontWeight};
  font-style: ${fontStyles.paragraphFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`


// ------ Unique Elements


export const Address = styled.address`
  color: ${baseStyles.colorPrimary};
  margin-bottom: ${fontStyles.paragraphSpacing}px;
  font-size: ${baseStyles.baseFontSize}rem;
  font-family: ${fontStyles.paragraphFontFamily};
  font-weight: bold;
  font-style: ${fontStyles.paragraphFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`