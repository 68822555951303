// Base Values
export const baseStyles = {
  // Fonts
  baseFontSize: 1.625,
  primaryFontFamily: 'Amri',
  secondaryFontFamily: 'Open Sans',
  tertiaryFontFamily: 'Abahaya Libre',
  
  // Default Spacing
  basePadding: 130,
  baseMargin: 45,
  maxWidth: 1200,
  
  // Colors
  colorPrimary: '#303D48',
  colorSecondary: '#FF6767',
  colorTertiary: '#EBEAE0',
  colorQuaternary: '#5E5E5E',
  colorQuinary: '#F7F7F7',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000'
}

export const fontStyles = {

  // Titles -- h1, h2, h3
  titleFontFamily: baseStyles.primaryFontFamily,
  titleFontWeight: 'regular',
  titleFontStyle: 'initial',
  titleFontScaling: 1,
  
  // Subtitles -- h4, h5
  subtitleColor: baseStyles.colorQuaternary,
  subtitleFontFamily: baseStyles.secondaryFontFamily,
  subtitleFontWeight: 'regular',
  subtitleFontStyle: 'initial',

  // Paragraphs -- p, strong
  paragraphSpacing: 45,
  paragraphFontFamily: baseStyles.primaryFontFamily,
  paragraphFontWeight: '100',
  paragraphFontStyle: 'initial',

  // List Styles -- ul, ol

  // List Item Styles -- li

  // Unique Styles -- address
}

export const linkStyles = {
  linkFontSize: baseStyles.baseFontSize * 0.75,
  linkFontFamily: baseStyles.tertiaryFontFamily,
  linkFontWeight: '100',
  linkFontStyle: 'initial',
}

export const formStyles = {
  // Labels
  labelFontSize: baseStyles.baseFontSize,
  labelFontFamily: baseStyles.secondaryFontFamily,
  labelFontWeight: '100',
  labelFontStyle: 'initial',

  // Inputs
  inputFontSize: baseStyles.baseFontSize,
  inputFontFamily: baseStyles.primaryFontFamily,
  inputFontWeight: '100',
  inputFontStyle: 'initial',

  // Textarea
  textareaFontSize: baseStyles.baseFontSize,
  textareaFontFamily: baseStyles.primaryFontFamily,
  textareaFontWeight: '100',
  textareaFontStyle: 'initial',

  // ErrorItems
  errorFontSize: baseStyles.baseFontSize,
  errorFontFamily: baseStyles.secondaryFontFamily,
  errorFontWeight: '100',
  errorFontStyle: 'initial',
}

export const buttonStyles = {
  buttonColorPrimary: '#000000',
  buttonColorSecondary: '#FFFFFF',
  buttonFontSize: baseStyles.baseFontSize * 1,
  buttonFontFamily: baseStyles.secondaryFontFamily,
  buttonFontWeight: '100',
  buttonFontStyle: 'initial',
}