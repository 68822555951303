import { StaticQuery, graphql } from 'gatsby'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import { Container, Row, Col } from '../html/Layout'
import { Link } from '../html/Links'
import { H3, H5 } from '../html/Fonts'
import { baseStyles } from '../html/_config'
import { urlMaker } from '../../utility/blog';
import TimeIndicator from '../molecules/TimeIndicator';



const BlogNav = ({ children, visible }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(    
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
          limit: 4
        ) {
          edges {
            node {
              fileAbsolutePath
              frontmatter {
                title
                date
                subtitle
                image
              }
            }
          }
        }
      }
    `}
    render={data => (
      data.allMarkdownRemark.edges.length > 0 ?
      <Container fluid id='blog-nav' css={css`
        z-index: 10;
        position: absolute;
        top: 69px;
        left: 0;
        padding: 45px 0!important;
        width: 100vw!important;
        background: ${baseStyles.colorQuinary};
        transition: 0.5s;

        ${visible ? `
          opacity: 1;
          z-index: 10;
        ` : `
          pointer-events: none;
          opacity: 0;
        `}
      `}>
        <Row>
          {data && 
            data.allMarkdownRemark.edges.map((edge, i) => (
              edge.node &&
                <Col as='article' xs={3} key={i}>
                  <Link 
                    css={css`
                      border-bottom: 1px solid ${baseStyles.colorQuaternary};
                      &:hover {
                        opacity: 0.6;
                        &:after {
                          width: 0%;
                        }
                      }
                    `}
                    to={urlMaker(edge.node.fileAbsolutePath)}
                  >
                    <div 
                      css={css`
                        width: 100%;
                        height: 125px;
                        overflow: hidden;
                        margin-bottom: 15px;
                      `}
                    >
                      <img 
                        css={css`max-width: 100%;`}
                        alt={edge.node.frontmatter.subtitle}
                        src={edge.node.frontmatter.image}
                      />
                    </div>
                    <H3 css={css`margin-bottom: 15px; color: ${baseStyles.colorPrimary}`}>{edge.node.frontmatter.title}</H3>
                    <H5>{edge.node.frontmatter.subtitle}</H5>
                    <TimeIndicator sm time={edge.node.frontmatter.date}/>
                  </Link>
                </Col>
            ))
          }
        </Row>
      </Container>
      :
      <Container fluid id='blog-nav' css={css`
        z-index: 10;
        position: absolute;
        top: 69px;
        left: 0;
        padding: 45px 0!important;
        width: calc(100vw - 15px)!important;
        background: ${baseStyles.colorQuinary};
        transition: 0.5s;

        ${visible ? `
          opacity: 1;
          z-index: 10;
        ` : `
          pointer-events: none;
          opacity: 0;
        `}
      `}>
        <Row as='section' css={css`max-width: ${baseStyles.maxWidth}px; margin: 0 auto!important;`}>
          <Col>
            <H3 css={css`margin-bottom: 0;`}>Stories of Care Coming Soon...</H3>
          </Col>
        </Row>
      </Container>
    )}
  />
)

export default BlogNav